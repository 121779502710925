import React from "react";
import theme from "theme";
import { Theme, Image, Text, Box, Section } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"services"} />
		<Helmet>
			<title>
				Предложения | Aventurinsky Tennis Courts
			</title>
			<meta name={"description"} content={"Повысьте уровень своей игры с помощью услуг высшего класса"} />
			<meta property={"og:title"} content={"Предложения | Aventurinsky Tennis Courts"} />
			<meta property={"og:description"} content={"Повысьте уровень своей игры с помощью услуг высшего класса"} />
			<meta property={"og:image"} content={"https://aventurinsky.com/img/1.jpg"} />
			<link rel={"shortcut icon"} href={"https://aventurinsky.com/img/940623464346.png"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://aventurinsky.com/img/940623464346.png"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://aventurinsky.com/img/940623464346.png"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://aventurinsky.com/img/940623464346.png"} />
			<link rel={"apple-touch-startup-image"} href={"https://aventurinsky.com/img/940623464346.png"} />
			<meta name={"msapplication-TileImage"} content={"https://aventurinsky.com/img/940623464346.png"} />
		</Helmet>
		<Components.Header />
		<Section padding="80px 0 90px 0" background="--color-light" quarkly-title="Product-4">
			<Override slot="SectionContent" align-items="center" />
			<Box
				min-width="100px"
				min-height="100px"
				display="block"
				grid-template-columns="repeat(3, 1fr)"
				grid-template-rows="auto"
				grid-gap="0 35px"
				md-grid-template-columns="1fr"
				md-grid-gap="40px 0"
				margin="0px 0px 0 0px"
				lg-margin="0px 0px 0 0px"
			>
				<Box
					min-width="100px"
					min-height="100px"
					display="flex"
					flex-direction="row"
					align-items="center"
					md-flex-direction="column"
				>
					<Image
						src="https://aventurinsky.com/img/4.jpg"
						display="block"
						max-width="100%"
						margin="0px 0px 0 0px"
						height="500px"
						width="50%"
						object-fit="cover"
						sm-height="220px"
						md-width="100%"
						md-margin="0px 0px 50px 0px"
						sm-margin="0px 0px 35px 0px"
					/>
					<Box
						min-width="100px"
						min-height="100px"
						padding="0px 80px 0px 80px"
						width="50%"
						lg-padding="0px 50px 0px 50px"
						md-width="100%"
						sm-padding="0px 0 0px 0"
					>
						<Text margin="0px 0px 15px 0px" font="normal 600 42px/1.2 --fontFamily-sans" text-align="left" lg-font="normal 600 36px/1.2 --fontFamily-sans">
						Комплексные услуги для тенниса
						</Text>
						<Text margin="0px 0px 50px 0px" font="normal 300 18px/1.5 --fontFamily-sansHelvetica" lg-margin="0px 0px 40px 0px" sm-margin="0px 0px 30px 0px">
						В Aventurinsky Tennis Courts мы не только предоставляем отличные корты, но и предлагаем полный спектр услуг, призванных улучшить ваш теннисный опыт. Если вам нужны высококлассные тренировки, аренда оборудования или организация мероприятий, наш центр оборудован для удовлетворения всех ваших теннисных потребностей.
						</Text>
					</Box>
				</Box>
			</Box>
		</Section>
		<Section padding="88px 0 88px 0" lg-padding="56px 0 56px 0" sm-padding="32px 0 32px 0" background="--color-light">
			<Override slot="SectionContent" max-width="1220px" />
			<Box margin="0px 0px 10px 0px" padding="15px 15px 15px 15px" md-margin="0px 0px 40px 0px" lg-margin="0px 0px 56px 0px">
				<Text
					margin="0px 0px 24px 0px"
					font="--headline2"
					color="--dark"
					text-align="center"
					md-font="normal 700 42px/1.2 &quot;Source Sans Pro&quot;, sans-serif"
				>
					Комплексные услуги для тенниса
				</Text>
			</Box>
			<Box
				display="flex"
				margin="0px 0px 49px 0px"
				md-flex-direction="column"
				md-align-items="center"
				md-justify-content="center"
			>
				<Box
					width="30%"
					padding="48px 15px 15px 15px"
					align-items="flex-start"
					display="flex"
					justify-content="center"
					border-style="solid"
					border-width="2px 0px 0px 0px"
					border-color="--color-lightD2"
					margin="0px 10% 0px 0px"
					lg-margin="0px 30px 0px 0px"
					md-width="100%"
					sm-padding="24px 15px 15px 15px"
					md-justify-content="flex-start"
					md-margin="0px 0px 0px 0px"
					sm-justify-content="center"
				>
					<Text margin="0px 0px 0px 0px" font="--headline3" color="--darkL2">
					Экспертные тренировки и обучение
					</Text>
				</Box>
				<Box
					width="70%"
					padding="48px 15px 15px 15px"
					display="flex"
					align-items="flex-start"
					justify-content="flex-start"
					border-style="solid"
					border-width="2px 0px 0px 0px"
					border-color="--color-lightD2"
					md-width="100%"
					md-border-width="0px"
					sm-align-items="center"
					sm-flex-direction="column"
					sm-justify-content="center"
					sm-padding="25px 15px 15px 15px"
					md-padding="33px 15px 15px 15px"
				>
					<Box
						padding="0px 0px 0px 0px"
						width="100%"
						sm-align-items="center"
						sm-display="flex"
						sm-flex-direction="column"
						sm-justify-content="center"
					>
						<Text margin="0px 0px 0px 0px" font="--lead" color="--darkL2" sm-text-align="center">
						Наша команда профессиональных тренеров стремится помочь вам улучшить свою игру. Будь вы начинающий или продвинутый игрок, мы предлагаем программы тренировок, разработанные с учетом вашего уровня мастерства. Наши тренеры обладают многолетним опытом и страстью к спорту, что гарантирует вам наилучшее руководство.
							<br />
							<br />
							
Индивидуальные программы тренировок: Индивидуальные занятия, разработанные с учетом ваших конкретных потребностей и целей.
							<br />
    Уроки для начинающих: Для новичков в теннисе, с упором на основы.
							<br />
    Промежуточные тренировки: Совершенствуйте свои навыки и стратегии.
							<br />
    Продвинутый тренер: для конкурентоспособных игроков, желающих усовершенствовать свою игру.
							<br />
Групповые занятия: Учитесь и практикуйте в благоприятной групповой обстановке.
							<br />
							
    Групповые занятия для взрослых: Идеально подходит для любителей.
							<br />
    Групповые занятия для юниоров: Предназначены для молодых игроков, чтобы развивать их навыки.
							<br />
    Семейные уроки: Веселое времяпрепровождение для всей семьи, независимо от уровня подготовки.

						</Text>
					</Box>
				</Box>
			</Box>
			<Box
				display="flex"
				margin="0px 0px 49px 0px"
				md-flex-direction="column"
				md-align-items="center"
				md-justify-content="center"
			>
				<Box
					width="30%"
					padding="48px 15px 15px 15px"
					align-items="flex-start"
					display="flex"
					justify-content="center"
					border-style="solid"
					border-width="2px 0px 0px 0px"
					border-color="--color-lightD2"
					margin="0px 10% 0px 0px"
					lg-margin="0px 30px 0px 0px"
					md-width="100%"
					sm-padding="24px 15px 15px 15px"
					md-justify-content="flex-start"
					md-margin="0px 0px 0px 0px"
					sm-justify-content="center"
				>
					<Text margin="0px 0px 0px 0px" font="--headline3" color="--darkL2">
					Корты премиум-класса
					</Text>
				</Box>
				<Box
					width="70%"
					padding="48px 15px 15px 15px"
					display="flex"
					align-items="flex-start"
					justify-content="flex-start"
					border-style="solid"
					border-width="2px 0px 0px 0px"
					border-color="--color-lightD2"
					md-width="100%"
					md-border-width="0px"
					sm-align-items="center"
					sm-flex-direction="column"
					sm-justify-content="center"
					sm-padding="25px 15px 15px 15px"
					md-padding="33px 15px 15px 15px"
				>
					<Box
						padding="0px 0px 0px 0px"
						width="100%"
						sm-align-items="center"
						sm-display="flex"
						sm-flex-direction="column"
						sm-justify-content="center"
					>
						<Text margin="0px 0px 0px 0px" font="--lead" color="--darkL2" sm-text-align="center">
						Наши корты поддерживаются в соответствии с самыми высокими стандартами, обеспечивая отличное игровое покрытие круглый год. Мы предлагаем различные варианты кортов, чтобы удовлетворить различные предпочтения и стили игры.
							<br />
							<br />
Крытые корты: Играйте в любую погоду на наших крытых кортах с регулируемым климатом.
							<br />
    Жесткие корты: Стандартное игровое покрытие, используемое во многих турнирах.
							<br />
    Глиняные корты: Более мягкое покрытие, обеспечивающее разные условия для игры.
							<br />
    Корты из синтетической травы: Удобные и легкие для суставов.
							<br />
Корты на открытом воздухе: Наслаждайтесь свежим воздухом и естественным освещением.
							<br />
    Освещенные солнцем корты: Идеально подходят для игры в дневное время.
							<br />
    Освещаемые корты: Играйте до вечера на наших хорошо освещенных открытых кортах.
							<br />
    Затененные корты: Идеально подходят для жарких летних дней.

						</Text>
					</Box>
				</Box>
			</Box>
			<Box
				display="flex"
				margin="0px 0px 49px 0px"
				md-flex-direction="column"
				md-align-items="center"
				md-justify-content="center"
			>
				<Box
					width="30%"
					padding="48px 15px 15px 15px"
					align-items="flex-start"
					display="flex"
					justify-content="center"
					border-style="solid"
					border-width="2px 0px 0px 0px"
					border-color="--color-lightD2"
					margin="0px 10% 0px 0px"
					lg-margin="0px 30px 0px 0px"
					md-width="100%"
					sm-padding="24px 15px 15px 15px"
					md-justify-content="flex-start"
					md-margin="0px 0px 0px 0px"
					sm-justify-content="center"
				>
					<Text margin="0px 0px 0px 0px" font="--headline3" color="--darkL2">
					Прокат оборудования и профессиональный магазин
					</Text>
				</Box>
				<Box
					width="70%"
					padding="48px 15px 15px 15px"
					display="flex"
					align-items="flex-start"
					justify-content="flex-start"
					border-style="solid"
					border-width="2px 0px 0px 0px"
					border-color="--color-lightD2"
					md-width="100%"
					md-border-width="0px"
					sm-align-items="center"
					sm-flex-direction="column"
					sm-justify-content="center"
					sm-padding="25px 15px 15px 15px"
					md-padding="33px 15px 15px 15px"
				>
					<Box
						padding="0px 0px 0px 0px"
						width="100%"
						sm-align-items="center"
						sm-display="flex"
						sm-flex-direction="column"
						sm-justify-content="center"
					>
						<Text margin="0px 0px 0px 0px" font="--lead" color="--darkL2" sm-text-align="center">
						Мы предоставляем высококачественное теннисное оборудование для улучшения вашей игры. Если вам нужно арендовать или купить, в нашем магазине есть все необходимое.
							<br />
							<br />
Прокат и продажа ракеток: Высококачественные ракетки можно взять напрокат или купить.
							<br />
    Последние модели: Попробуйте самые новые ракетки на рынке.
							<br />
    Индивидуальная настройка: Натяните ракетку в соответствии с вашими требованиями.
							<br />
    Демо-версии ракеток: Протестируйте перед покупкой.
							<br />
Одежда и аксессуары: Выглядите и чувствуйте себя лучше всех на корте.
							<br />
    Эффективная одежда: Оставайтесь комфортными и стильными.
							<br />
    Обувь: Теннисная обувь, предназначенная для оптимальной игры.
							<br />
    Аксессуары: От рукояток до сумок - найдите то, что вам нужно.

						</Text>
					</Box>
				</Box>
			</Box>
			<Box
				display="flex"
				margin="0px 0px 49px 0px"
				md-flex-direction="column"
				md-align-items="center"
				md-justify-content="center"
			>
				<Box
					width="30%"
					padding="48px 15px 15px 15px"
					align-items="flex-start"
					display="flex"
					justify-content="center"
					border-style="solid"
					border-width="2px 0px 0px 0px"
					border-color="--color-lightD2"
					margin="0px 10% 0px 0px"
					lg-margin="0px 30px 0px 0px"
					md-width="100%"
					sm-padding="24px 15px 15px 15px"
					md-justify-content="flex-start"
					md-margin="0px 0px 0px 0px"
					sm-justify-content="center"
				>
					<Text margin="0px 0px 0px 0px" font="--headline3" color="--darkL2">
					Проведение мероприятий
					</Text>
				</Box>
				<Box
					width="70%"
					padding="48px 15px 15px 15px"
					display="flex"
					align-items="flex-start"
					justify-content="flex-start"
					border-style="solid"
					border-width="2px 0px 0px 0px"
					border-color="--color-lightD2"
					md-width="100%"
					md-border-width="0px"
					sm-align-items="center"
					sm-flex-direction="column"
					sm-justify-content="center"
					sm-padding="25px 15px 15px 15px"
					md-padding="33px 15px 15px 15px"
				>
					<Box
						padding="0px 0px 0px 0px"
						width="100%"
						sm-align-items="center"
						sm-display="flex"
						sm-flex-direction="column"
						sm-justify-content="center"
					>
						<Text margin="0px 0px 0px 0px" font="--lead" color="--darkL2" sm-text-align="center">
						Aventurinsky Tennis Courts - идеальное место для проведения вашего следующего теннисного мероприятия. Будь то корпоративный турнир, товарищеский матч или крупное соревнование, у нас есть все условия и услуги, чтобы сделать его успешным.
							<br />
							<br />
Организация турниров: Профессиональный менеджмент для бесшовных мероприятий.
							<br />
    Корпоративные турниры: Создание команды с помощью тенниса.
							<br />
    Благотворительные мероприятия: Проведите турнир на благое дело.
							<br />
    Соревнования для юниоров: Поощряйте юные таланты с помощью хорошо организованных мероприятий.
							<br />
Частные мероприятия: Отпразднуйте вечеринку или встречу на теннисную тематику.
							<br />
    Вечеринки по случаю дня рождения: Веселые и активные праздники.
							<br />
    Светские рауты: Наслаждайтесь теннисом с друзьями и семьей.
							<br />
    Особые случаи: Сделайте свое мероприятие незабываемым, воспользовавшись нашими возможностями.
						</Text>
					</Box>
				</Box>
			</Box>
		</Section>
		<Section
			padding="90px 0 90px 0"
			sm-padding="60px 0 60px 0"
			background="--color-light"
			color="--dark"
			md-padding="60px 0 60px 0"
			quarkly-title="Advantages/Features-27"
		>
			<Override slot="SectionContent" max-width="1220px" md-align-items="center" sm-min-width="280px" />
			<Box border-radius="24px" margin="0px 0px 20px 0px" md-margin="0px 0px 10px 0px" width="80%">
				<Text
					color="--greyD3"
					md-text-align="center"
					margin="0px 0px 0px 0px"
					font="--headline2"
					width="100%"
					letter-spacing="-1px"
					lg-width="100%"
					md-font="--headline3"
				>
					Узнайте больше на теннисных кортах Aventurinsky
				</Text>
			</Box>
			<Box lg-flex-wrap="wrap">
				<Text
					margin="20px 0px 40px 0px"
					color="--greyD2"
					font="--base"
					lg-text-align="left"
					md-text-align="center"
				>
					И это только начало. Откройте для себя весь спектр наших предложений, связавшись с нами или посетив наш объект. Наш дружелюбный персонал всегда готов помочь вам и предоставить более подробную информацию о наших услугах.
				</Text>
				<Box
					sm-margin="0px 0px 30px 0px"
					sm-width="100%"
					display="flex"
					flex-direction="column"
					justify-content="space-between"
					md-align-items="center"
				>
					<Text
						margin="0px 0px 18px 0px"
						color="--darkL2"
						font="--headline3"
						lg-text-align="left"
						md-font="--lead"
						md-text-align="center"
					>
						Повысьте свой уровень игры с помощью услуг высшего класса
					</Text>
					<Text
						margin="0px 0px 0px 0px"
						color="--greyD2"
						font="--base"
						lg-text-align="left"
						md-text-align="center"
					>
						Испытайте лучшее в теннисе на теннисных кортах Aventurinsky. Запишитесь на занятия сегодня и поднимите свою игру на новый уровень.
					</Text>
				</Box>
			</Box>
		</Section>
		<Components.Footer />
		<RawHtml>
			<style place={"endOfHead"} rawKey={"65323428c5fd0f0020b15081"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});